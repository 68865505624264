@font-face {
    font-family: 'WhitneyBold';
    src: url('../fonts/whitneybold.otf') format("opentype");
    font-weight: 700;
}
@font-face {
    font-family: 'WhitneyLight';
    src: url('../fonts/whitneylight.otf') format("opentype");
    font-weight: 700;
}
@font-face {
    font-family: 'TisaPro';
    src: url('../fonts/Tisa\ Sans\ Pro\ Light.woff') format("woff");
    font-weight: 300;
}
@font-face {
    font-family: 'TisaProBold';
    src: url('../fonts/Tisa\ Sans\ Pro\ Bold.woff') format("woff");
    font-weight: 700;
}
@font-face {
    font-family: 'TisaProRegular';
    src: url('../fonts/Tisa\ Sans\ Pro\ Regular.woff') format("woff");
    font-weight: 700;
}

body {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.whitney-bold {
    font-family: 'WhitneyBold';
    font-style: normal;
    font-weight: 700;
}

.whitney-light {
    font-family: 'WhitneyLight';
    font-style: normal;
    font-weight: 700;
}

.bold-tisa-pro {
    font-family: 'TisaProBold';
}
