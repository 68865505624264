html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: WhitneyBold;
  src: url("whitneybold.17f0e045.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: WhitneyLight;
  src: url("whitneylight.fadc1ad1.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: TisaPro;
  src: url("Tisa Sans Pro Light.d7ac1c58.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: TisaProBold;
  src: url("Tisa Sans Pro Bold.a1c05002.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: TisaProRegular;
  src: url("Tisa Sans Pro Regular.cbe041d6.woff") format("woff");
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

.whitney-bold {
  font-family: WhitneyBold;
  font-style: normal;
  font-weight: 700;
}

.whitney-light {
  font-family: WhitneyLight;
  font-style: normal;
  font-weight: 700;
}

.bold-tisa-pro {
  font-family: TisaProBold;
}

.space-between {
  justify-content: space-between;
  display: flex;
}

.space-evenly {
  justify-content: space-evenly;
  display: flex;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.mobile {
  display: none;
}

.oaf-start {
  width: 100%;
  background-color: #0000;
  background-image: linear-gradient(#0000 60%, #000), url("background1.36ef0597.png");
  background-position: 0 0, 0 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.oaf-start-connection {
  width: 100%;
  background-color: #0000;
  background-image: linear-gradient(#0000 60%, #000), url("background.de303de0.png");
  background-position: 0 0, 0 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.oaf-start-almost-there {
  width: 100%;
  background-color: #0000;
  background-image: linear-gradient(#0000 60%, #000), url("background.9c885c78.png");
  background-position: 0 0, 0 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.oaf-logo {
  content: url("oaf-logo.58066daf.png");
  margin-top: 30px;
}

.oaf-start-content {
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.oaf-start-main-content {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.oaf-cta {
  height: 100%;
  text-align: left;
  background-color: #fffffff0;
  border-radius: 1px;
  flex: 0 568px;
  margin-top: 140px;
  padding-bottom: 100px;
}

.oaf-cta h1 {
  margin: 43px 40px 0;
  font-size: 31px;
  line-height: 43px;
}

.oaf-cta p {
  font-family: TisaPro;
  font-size: 19px;
  line-height: 31px;
}

.oaf-cta-desktop-content {
  color: #000;
  visibility: visible;
  flex: 1 0 489px;
  margin: 20px 40px;
}

.oaf-cta-mobile-content {
  visibility: hidden;
  color: #000;
  flex: 1 0 489px;
  margin: 20px 40px;
  display: none;
}

.hide-mobile-cta {
  display: none !important;
}

.show-all-cta {
  visibility: visible;
  display: block !important;
}

.read-more-area {
  width: 100px;
  margin-top: 30px;
}

.read-more-area:hover {
  cursor: pointer;
}

.arrow-down {
  border: 2px solid #ce482d;
  border-width: 0 2px 2px 0;
  margin-bottom: 2.5px;
  margin-left: 4px;
  padding: 2.5px;
  display: inline-block;
  transform: rotate(45deg);
}

.read-more {
  color: #ce482d;
  margin: 5px auto auto;
  text-decoration: underline;
}

.oaf-cta-mobile-content > a {
  font-size: 17px;
  line-height: 20px;
}

.oaf-donation {
  height: 100%;
  background: #2b7f68;
  border-radius: 1px 1px 0 0;
  flex: 0 452px;
  margin-top: 140px;
  margin-bottom: 90px;
  padding: 0 15px 10px;
}

.mobile-background {
  display: none;
}

.oaf-donation iframe {
  max-width: 100%;
  min-width: 250px;
  margin: 0 auto -25px;
  display: block;
  max-height: none !important;
}

.donation-info-text {
  justify-content: center;
  padding: 15px 0;
  display: flex;
}

.donation-info-text > div {
  width: 66%;
  align-items: center;
  display: flex;
}

.donation-info-text > div > img {
  width: 30px;
  height: 40px;
  margin-right: 15px;
  display: block;
}

.donation-info-text h3 {
  color: #fff;
  margin: 0;
  font-size: 23px;
  display: inline;
}

.asterisk {
  color: #fff;
  text-align: center;
  font-family: TisaPro;
  font-size: 14px;
  line-height: 20px;
}

.oaf-middle {
  background-image: url("leaf-background1.3ec83de7.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: translateY(-13%);
}

.oaf-middle-content {
  height: 100%;
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
  padding: 150px 15px 100px;
}

.reasons-header {
  color: #fff;
  font-size: 39px;
  line-height: 43px;
}

.icon-section {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.icon-section p {
  color: #fff;
  flex: 1 0 323px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.icon-section > img {
  width: 215px;
  height: 215px;
  margin-bottom: 10px;
}

.subtitle-text {
  text-align: center;
  font-size: 25px;
  line-height: 43px;
}

.reason-text {
  text-align: center;
  font-family: TisaProRegular;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
}

.oaf-end {
  flex: 0 310px;
}

.oaf-end-content {
  width: 1140px;
  max-width: 100%;
  margin: -80px auto 0;
}

.quote-area {
  height: 100%;
}

.quote-icon {
  color: #2b7f68;
  margin-top: 50px;
  margin-left: 20px;
  font-size: 100px;
  font-weight: 700;
  line-height: 43px;
}

.quote-text {
  margin-left: 15px;
  padding: 0 15px;
  font-family: TisaPro;
  font-size: 28px;
  font-style: italic;
  font-weight: 300;
  line-height: 40px;
}

.oaf-quote {
  flex-direction: column;
  justify-content: space-evenly;
  display: flex;
}

.oaf-quote > hr {
  width: 89px;
  height: 0;
  border: 2px solid #2b7f68;
  margin-left: 100px;
}

.author-text {
  color: #2b7f68;
  margin-left: 100px;
  font-size: 22px;
  line-height: 43px;
}

@media (max-width: 767px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .oaf-start, .oaf-start-connection, .oaf-start-almost-there {
    background-image: none;
    background-size: cover;
    background-position-y: translateY(-50px);
  }

  .oaf-start-content {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    padding: 0;
  }

  .logo-area {
    height: 300px;
    background-color: #0000;
    background-image: linear-gradient(#0000 60%, #000), url("cc-mobile-background.9f816e55.png");
    background-position: 0 0, 0 0;
    background-repeat: repeat, repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
  }

  .logo-area-connection {
    height: 300px;
    background-color: #0000;
    background-image: linear-gradient(#0000 60%, #000), url("mobile-background.6dea1548.png");
    background-position: 0 0, 0 0;
    background-repeat: repeat, repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
  }

  .logo-area-almost-there {
    height: 300px;
    background-color: #0000;
    background-image: linear-gradient(#0000 60%, #000), url("mobile-background.5fc21633.png");
    background-position: 0 0, 0 0;
    background-repeat: repeat, repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
  }

  .oaf-logo {
    width: 187px;
    height: 43px;
    margin-left: 45px;
  }

  .oaf-start-main-content {
    flex: 0 345px;
    margin: auto;
    display: block;
  }

  .oaf-cta {
    background-color: #fff;
    margin: -150px 15px auto;
    padding-bottom: 0;
  }

  .oaf-cta h1 {
    padding-top: 20px;
    font-size: 22px;
    line-height: 30px;
  }

  .oaf-cta p {
    flex: 1 0 298px;
    margin: 30px auto auto;
    font-size: 16px;
    line-height: 24px;
  }

  .oaf-cta-mobile-content {
    visibility: visible;
    display: block;
  }

  .oaf-cta-desktop-content {
    display: none;
  }

  .oaf-donation {
    margin: auto;
  }

  .mobile-background {
    height: 830px;
    width: 100%;
    margin-bottom: -700px;
    padding-bottom: -200px;
    display: block;
  }

  .donorbox-area {
    text-align: center;
    height: 100%;
    padding-bottom: 20px;
  }

  .oaf-donation iframe {
    max-width: 460px;
    padding: 0 15px 10px;
  }

  .donation-info-text {
    margin-top: 0;
  }

  .oaf-middle {
    background-color: #ded6c0;
    background-image: none;
    transform: translateY(0);
  }

  .oaf-middle-content {
    padding: 50px 15px;
  }

  .reasons-header {
    color: #000;
    flex: 1 0 307px;
    margin: auto;
    padding: 0 15px;
    font-size: 25px;
    line-height: 32px;
  }

  .icon-area {
    flex-direction: column;
    margin: auto;
  }

  .icon-section {
    margin: 30px auto auto;
  }

  .icon-section > p {
    color: #000;
  }

  .subtitle-text {
    font-size: 23px;
    line-height: 43px;
  }

  .reason-text {
    font-size: 16px;
    line-height: 24px;
  }

  .oaf-end-content {
    flex-direction: column;
    margin-top: 20px;
    padding-left: 0;
  }

  .oaf-author {
    margin: auto auto 20px;
  }

  .oaf-quote {
    justify-content: center;
    margin: auto;
  }

  .quote-area {
    align-items: flex-start;
    margin: auto;
  }

  .quote-icon {
    font-size: 90px;
    line-height: 43px;
  }

  .quote-text {
    flex: 1 0 240px;
    font-size: 23px;
    line-height: 35px;
  }

  .oaf-quote hr, .author-text {
    margin-left: 95px;
  }
}

/*# sourceMappingURL=index-almost-there.38be77cf.css.map */
