@import "../../node_modules/normalize.css/normalize.css";
@import "standard.css";


/* GENERAL STYLE FEATURES */

.space-between {
    display: flex;
    justify-content: space-between;
}
.space-evenly {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-evenly;
}
.flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.mobile {
    display: none;
}



/* STARTING SECTION */

.oaf-start {
    background:linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,1)), url('../images/background1.png');
    background-size: cover;
    width: 100%;
}
.oaf-start-connection {
    background:linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,1)), url('../images/connection/background.png');
    background-size: cover;
    width: 100%;
}
.oaf-start-almost-there {
    background:linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,1)), url('../images/almost-there/background.png');
    background-size: cover;
    width: 100%;
}

.oaf-logo {
    margin-top: 30px;
    content: url("../images/oaf-logo.png");
}
.oaf-start-content {
    width: 1140px;
    padding: 0 15px;
    max-width: 100%;
    margin: 0 auto;
}
.oaf-start-main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.oaf-cta {
    flex: 0 1 568px;
    height: 100%;
    
    border-radius: 1px;
    background-color: rgba(255, 255, 255, 0.94);
    text-align: left;
    margin-top: 140px;
    padding-bottom: 100px;
}
.oaf-cta h1 {
    font-size: 31px;
    line-height: 43px;
    margin: 0 40px;
    margin-top: 43px;
}
.oaf-cta p {
    font-family: 'TisaPro';
    font-size: 19px;
    line-height: 31px;
}
.oaf-cta-desktop-content {
    flex: 1 0 489px;
    margin: 20px 40px;
    color: #000000;
    visibility: visible;
}
.oaf-cta-mobile-content {
    display: none;
    visibility: hidden;
    flex: 1 0 489px;
    margin: 20px 40px;
    color: #000000;
}
.hide-mobile-cta {
    display: none !important;
}
.show-all-cta {
    display: block !important;
    visibility: visible;
}

.read-more-area {
    margin-top: 30px;
    width: 100px;
}
.read-more-area:hover {
    cursor: pointer;
}
.arrow-down {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-color: #CE482D;
    margin-left: 4px;
    margin-bottom: 2.5px;
}
.read-more {
    color: #CE482D;
    margin: auto;
    margin-top: 5px;
    text-decoration: underline;
}
.oaf-cta-mobile-content > a {
    font-size: 17px;
    line-height: 20px;
}

.oaf-donation {
    flex: 0 1 452px;
    background:#2B7F68;
    border-radius: 1px 1px 0px 0px;
    margin-top: 140px;
    padding: 0 15px 10px;
    margin-bottom: 90px;
    height: 100%;
}

.mobile-background {
    display: none;
}

.oaf-donation iframe {
    max-width: 100%;
    min-width: 250px;
    max-height: none !important;
    margin: 0 auto -25px;
    display: block;
}

.donation-info-text {
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.donation-info-text > div {
    width: 66%;
    display: flex;
    align-items: center;
}

.donation-info-text > div > img {
    width: 30px;
    height: 40px;
    display: block;
    margin-right: 15px;
}

.donation-info-text h3 {
    margin: 0;
    font-size: 23px;
    color: #FFFFFF;
    display: inline;
}

.asterisk {
    color: white;
    font-family: 'TisaPro';
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}



/* MIDDLE SECTION */


.oaf-middle {
    background-image: url("../images/leaf-background1.png");
    transform: translateY(-13%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.oaf-middle-content {
    height: 100%;
    width: 1140px;
    max-width: 100%;
    margin: 0 auto;
    padding: 150px 15px 100px 15px;
}
.reasons-header {
    font-size: 39px;
    line-height: 43px;
    color: white;
}

.icon-section {
    margin: 0 auto;
    text-align: center;
    padding: 0 15px;
    width: 100%;
}
.icon-section p {
    flex: 1 0 323px;
    margin-top: 6px;
    margin-bottom: 6px;
    color: white;
}
.icon-section > img {
    margin-bottom: 10px;
    width: 215px;
    height: 215px;
}
.subtitle-text {
    font-size: 25px;
    line-height: 43px;
    text-align: center;
}
.reason-text {
    font-family: 'TisaProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 31px;
    text-align: center;
}



/* END SECTION */

.oaf-end {
    flex: 0 1 310px;
}
.oaf-end-content {
    width: 1140px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: -80px;
}

.quote-area {
    height: 100%;
}

.quote-icon {
    font-weight: 700;
    font-size: 100px;
    line-height: 43px;
    color: #2B7F68;
    margin-top: 50px;
    margin-left: 20px;
}

.quote-text {
    font-family: 'TisaPro';
    font-style: italic;
    font-weight: 300;
    font-size: 28px;
    line-height: 40px;
    margin-left: 15px;
    padding: 0 15px;
}

.oaf-quote {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.oaf-quote > hr {
    width: 89px;
    height: 0px;
    border: 2px solid #2B7F68;
    margin-left: 100px;
}

.author-text {
    font-size: 22px;
    line-height: 43px;
    color: #2B7F68;
    margin-left: 100px;
}



@media (max-width: 767px) {

    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }

    /* STARTING SECTION */

    .oaf-start {
        background-image: none;
        background-size: cover;
        background-position-y: translateY(-50px);
    }
    .oaf-start-connection {
        background-image: none;
        background-size: cover;
        background-position-y: translateY(-50px);
    }
    .oaf-start-almost-there {
        background-image: none;
        background-size: cover;
        background-position-y: translateY(-50px);
    }

    /* 768px */
    .oaf-start-content {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        padding: 0 0;
    }

    .logo-area {
        background:linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,1)), url('../images/cc-mobile-background.png');
        background-size: cover;
        margin: auto;
        height: 300px;
    }
    .logo-area-connection {
        background:linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,1)), url('../images/connection/mobile-background.png');
        background-size: cover;
        margin: auto;
        height: 300px;
    }
    .logo-area-almost-there {
        background:linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,1)), url('../images/almost-there/mobile-background.png');
        background-size: cover;
        margin: auto;
        height: 300px;
    }

    .oaf-logo {
        width: 187px;
        height: 43px;
        margin-left: 45px;
    }

    .oaf-start-main-content {
        margin: auto;
        
        flex: 0 1 345px;
        display:block;
    }

    .oaf-cta {
        background-color: rgba(255, 255, 255);
        margin: auto;
        padding-bottom: 0;
        margin-top: -150px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .oaf-cta h1 {
        font-size: 22px;
        line-height: 30px;
        padding-top: 20px;
    }
    .oaf-cta p {
        font-size: 16px;
        line-height: 24px;
        /* width: 298px; */
        flex: 1 0 298px;
        margin: auto;
        margin-top: 30px;
    }
    .oaf-cta-mobile-content {
        display: block;
        visibility: visible;
    }
    .oaf-cta-desktop-content {
        display: none;
    }

    .oaf-donation {
        margin: auto;
    }

    .mobile-background {
        display: block;
        padding-bottom: -200px;
        height: 830px;
        width: 100%;
        margin-bottom: -700px;
    }

    .donorbox-area {
        text-align: center;
        height: 100%;
        padding-bottom: 20px;
    }


    .oaf-donation iframe {
        max-width: 460px;
        padding: 0 15px 10px;
    }

    .donation-info-text {
        margin-top: 0;
    }



    /* MIDDLE SECTION */

    .oaf-middle {
        background-color: #DED6C0;
        transform: translateY(0);
        background-image: none;
    }
    .oaf-middle-content {
        padding: 50px 15px;
    }

    .reasons-header {
        flex: 1 0 307px;
        font-size: 25px;
        line-height: 32px;
        padding: 0 15px;
        margin: auto;
        color: black;
    }

    .icon-area {
        flex-direction: column;
        margin: auto;
    }
    .icon-section {
        margin: auto;
        margin-top: 30px;
    }
    .icon-section > p {
        color: black;
    }
    .subtitle-text {
        font-size: 23px;
        line-height: 43px;
    }
    .reason-text {
        font-size: 16px;
        line-height: 24px;
    }


    /* END SECTION */

    .oaf-end-content {
        flex-direction: column;
        padding-left: 0;
        margin-top: 20px;
    }
    .oaf-author {
        margin: auto;
        margin-bottom: 20px;
    }
    .oaf-quote {
        margin: auto;
        justify-content: center;
    }
    .quote-area {
        margin: auto;
        align-items: flex-start;
    }
    .quote-icon {
        font-size: 90px;
        line-height: 43px;
    }
    .quote-text {
        font-size: 23px;
        line-height: 35px;
        flex: 1 0 240px;
    }
    .oaf-quote hr {
        margin-left: 95px;
    }

    .author-text {
        margin-left: 95px;
    }
  }